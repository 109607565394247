<template>
  <app-layout-main class="p-4">
    <div
      class="
        container
        px-4
        m-auto
        mx-auto
        md:px-10
        max-w-lg
        space-y-7
        bg-contain bg-app-logo bg-no-repeat bg-center
      "
    >
      <app-logo-cryptopay :class="['w-full h-14']"/>

      <app-card>
        <h3 class="mb-4 font-semibold text-md text-center">
          {{ $t("auth.login.reset_password") }}
        </h3>

        <p class="mb-4 text-center text-gray-500 text-sm">
          {{ $t("auth.login.reset_message") }}
        </p>

        <form @submit.prevent="forgotPassword" class="space-y-5">
          <app-form-input
            type="email"
            autocomplete="email"
            v-model="user.email"
            :errorMessage="errors.email"
            :labelText="$t('general.email')"
          />
          <app-alert-error :message="errors.message" />

          <app-button type="submit" :loading="loading">
            {{ $t("auth.login.reset_my_password") }}
          </app-button>
        </form>

        <app-link class="mt-2" :to="{ name: 'login' }">
            {{ $t("auth.login.return_signin") }}
          </app-link>
      </app-card>

      <app-link
        :to="{ name: 'register' }"
        :text_before="$t('auth.login.dont_have_account')"
      >
        {{ $t("general.sign_up") }}
      </app-link>
    </div>
  </app-layout-main>
</template>

<script>
export default {
  name: "forgot-password-page",
  data() {
    return {
      emptyErrors: {
        email: [],
        message: null,
      },
    };
  },
  computed: {
    user() {
      return this.$store.getters["authStore/user"];
    },

    loading() {
      return this.$store.getters["authStore/loading"];
    },

    errors() {
      return this.$store.getters["authStore/errors"] ?? this.emptyErrors;
    },
  },
  methods: {
    async forgotPassword() {
      this.resetState();
      const resetPasswordResult = await this.$store.dispatch(
        "authStore/forgotPassword",
        this.user
      );

      if (resetPasswordResult == null) {
        return;
      }

      this.$router.push({
        name: "reset-password-notification",
        params: { email: resetPasswordResult },
      });
    },

    resetState() {
      this.$store.commit("authStore/resetState");
    },
  },
};
</script>
